<template>
  <div>
    <div class="over" v-if="showover">
      <div class="overbox">
        <img
          v-if="stage == 1"
          src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
          alt=""
        />

        <img
          v-if="stage == 2"
          src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
          alt=""
        />

        <img
          v-if="stage == 3"
          src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
          alt=""
        />

        <img
          v-if="stage == 4"
          src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
          alt=""
        />
        <div>恭喜你完成{{ stage }}阶的全部练习</div>
        <button @click="tofillin2">完成勋章报告 领取报告</button>
      </div>
    </div>
    <van-nav-bar
      title="练习列表"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
      @click-right="gohome"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
    </van-nav-bar>

    <div
      class="topracticelist"
      v-for="(item, index) in PracticeList"
      :key="index"
    >
      <div class="ctxItem">
        <div class="ctx-title">
          <div class="title-ctx">{{ item.task_title }}</div>
          <div class="title-fk"></div>
        </div>
        <div
          class="titleitem"
          v-for="(childListitem, childListindex) in item.childList"
          :key="childListindex"
          v-if="childListitem.is_unlocked == 1"
          @click="gotoitem(childListitem)"
        >
          <div class="titleitem-left">
            <!-- //分割线 -->

            <img
              v-if="
                childListitem.task_name == '入营指南' ||
                childListitem.task_name == '唤醒情绪觉察力' ||
                childListitem.task_name == '如何更好地觉察情绪' ||
                childListitem.task_name == '情绪合理化' ||
                childListitem.task_name == '情绪能量转化' ||
                childListitem.task_name == '对情绪的积极回应' ||
                childListitem.task_name == '应对情绪的方法' ||
                childListitem.task_name == '总结与拓展' ||
                childListitem.task_name == '积极倾听的开始' ||
                childListitem.task_name == '打开倾听的空间' ||
                childListitem.task_name == '捕捉倾听中的信息' ||
                childListitem.task_name == '深层次的倾听——共情' ||
                childListitem.task_name == '倾听中的支持性回应' ||
                childListitem.task_name == '调整倾听中的阻碍' ||
                childListitem.task_name == '沟通表达的力量' ||
                childListitem.task_name == '从客观事实出发' ||
                childListitem.task_name == '对自己的感受负责' ||
                childListitem.task_name ==
                  '良好沟通表达的助力——站在对方的视角' ||
                childListitem.task_name == '多提请求，而不是要求' ||
                childListitem.task_name == '应对沟通中的矛盾' ||
                childListitem.task_name == '幽默的积极力量' ||
                childListitem.task_name == '用适当的方式幽默' ||
                childListitem.task_name == '探索属于你的幽默感' ||
                childListitem.task_name == '幽默的化解力' ||
                childListitem.task_name == '幽默的修复力' ||
                childListitem.task_name == '丰富生活中的积极体验'
              "
              src="https://lesson.iapeap.com/images/EmotionalValue/lanryzn.png"
              alt=""
            />

            <img
              v-if="
                childListitem.task_name == '对自己的承诺' ||
                childListitem.task_name == '情绪探照灯' ||
                childListitem.task_name == '情绪命名挑战' ||
                childListitem.task_name == '情绪逻辑师' ||
                childListitem.task_name == '情绪探险家' ||
                childListitem.task_name == '情绪的时光机' ||
                childListitem.task_name == '情绪呼吸阀' ||
                childListitem.task_name == '情绪指南秘籍' ||
                childListitem.task_name == '倾听能量显微镜' ||
                childListitem.task_name == '倾听空间建筑师' ||
                childListitem.task_name == '倾听信号转接员' ||
                childListitem.task_name == '共情对讲机' ||
                childListitem.task_name == '倾听回应能量阀' ||
                childListitem.task_name == '倾听保护伞' ||
                childListitem.task_name == '倾听指南秘籍' ||
                childListitem.task_name == '沟通计划书' ||
                childListitem.task_name == '客观的表达者' ||
                childListitem.task_name == '感受代言人' ||
                childListitem.task_name == '同频输出者' ||
                childListitem.task_name == '请求提出者' ||
                childListitem.task_name == '冲突化解力' ||
                childListitem.task_name == '表达指南秘籍' ||
                childListitem.task_name == '幽默的线索' ||
                childListitem.task_name == '幽默的指南针' ||
                childListitem.task_name == '幽默探索号' ||
                childListitem.task_name == '幽默的降落伞' ||
                childListitem.task_name == '幽默的讲述者' ||
                childListitem.task_name == '生活的积极按钮' ||
                childListitem.task_name == '收获总结' ||
                childListitem.task_name == '倾听信号接收员'
              "
              src="https://lesson.iapeap.com/images/EmotionalValue/landzjcn.png"
              alt=""
            />

            <img
              v-if="childListitem.task_name == '情绪能量测验'"
              src="https://lesson.iapeap.com/images/EmotionalValue/lanqxjzcy.png"
              alt=""
            />

            <img
              v-if="childListitem.task_name == '情景训练场'"
              src="https://lesson.iapeap.com/images/EmotionalValue/lanqjxlc.png"
              alt=""
            />
            <img
              v-if="childListitem.task_name == '情绪日记'"
              src="https://lesson.iapeap.com/images/EmotionalValue/lanqxrj.png"
              alt=""
            />

            <!-- //分割线 -->
            <div class="task_name">{{ childListitem.task_name }}</div>
          </div>
          <!-- //是否完成 -->
          <img
            v-if="childListitem.is_complated == 1"
            class="duigou"
            src="https://lesson.iapeap.com/images/EmotionalValue/duigou.png"
            alt=""
          />
          <img
            v-if="childListitem.is_favourite == null"
            class="aixin"
            src="https://lesson.iapeap.com/images/EmotionalValue/xinhuiaixin.png"
            alt=""
            @click.stop="getsetFavourite(childListitem)"
          />
          <img
            v-if="childListitem.is_favourite == 1"
            class="aixin"
            src="https://lesson.iapeap.com/images/EmotionalValue/xinaixin.png"
            alt=""
            @click.stop="getcancelFavourite(childListitem)"
          />
        </div>

        <div
          class="titleitem2"
          v-for="(childListitem, childListindex) in item.childList"
          :key="childListindex"
          v-if="childListitem.is_unlocked == 0"
        >
          <div class="titleitem-left">
            <img
              v-if="
                childListitem.task_name == '入营指南' ||
                childListitem.task_name == '唤醒情绪觉察力' ||
                childListitem.task_name == '如何更好地觉察情绪' ||
                childListitem.task_name == '情绪合理化' ||
                childListitem.task_name == '情绪能量转化' ||
                childListitem.task_name == '对情绪的积极回应' ||
                childListitem.task_name == '应对情绪的方法' ||
                childListitem.task_name == '总结与拓展' ||
                childListitem.task_name == '积极倾听的开始' ||
                childListitem.task_name == '打开倾听的空间' ||
                childListitem.task_name == '捕捉倾听中的信息' ||
                childListitem.task_name == '深层次的倾听——共情' ||
                childListitem.task_name == '倾听中的支持性回应' ||
                childListitem.task_name == '调整倾听中的阻碍' ||
                childListitem.task_name == '沟通表达的力量' ||
                childListitem.task_name == '从客观事实出发' ||
                childListitem.task_name == '对自己的感受负责' ||
                childListitem.task_name ==
                  '良好沟通表达的助力——站在对方的视角' ||
                childListitem.task_name == '多提请求，而不是要求' ||
                childListitem.task_name == '应对沟通中的矛盾' ||
                childListitem.task_name == '幽默的积极力量' ||
                childListitem.task_name == '用适当的方式幽默' ||
                childListitem.task_name == '探索属于你的幽默感' ||
                childListitem.task_name == '幽默的化解力' ||
                childListitem.task_name == '幽默的修复力' ||
                childListitem.task_name == '丰富生活中的积极体验' ||
                childListitem.task_name == '情绪能量测验'
              "
              src="https://lesson.iapeap.com/images/EmotionalValue/hqxzn.png"
              alt=""
            />
            <img
              v-if="
                childListitem.task_name == '对自己的承诺' ||
                childListitem.task_name == '情绪探照灯' ||
                childListitem.task_name == '情绪命名挑战' ||
                childListitem.task_name == '情绪逻辑师' ||
                childListitem.task_name == '情绪探险家' ||
                childListitem.task_name == '情绪的时光机' ||
                childListitem.task_name == '情绪呼吸阀' ||
                childListitem.task_name == '情绪指南秘籍' ||
                childListitem.task_name == '倾听能量显微镜' ||
                childListitem.task_name == '倾听空间建筑师' ||
                childListitem.task_name == '倾听信号转接员' ||
                childListitem.task_name == '共情对讲机' ||
                childListitem.task_name == '倾听回应能量阀' ||
                childListitem.task_name == '倾听保护伞' ||
                childListitem.task_name == '倾听指南秘籍' ||
                childListitem.task_name == '沟通计划书' ||
                childListitem.task_name == '客观的表达者' ||
                childListitem.task_name == '感受代言人' ||
                childListitem.task_name == '同频输出者' ||
                childListitem.task_name == '请求提出者' ||
                childListitem.task_name == '冲突化解力' ||
                childListitem.task_name == '表达指南秘籍' ||
                childListitem.task_name == '幽默的线索' ||
                childListitem.task_name == '幽默的指南针' ||
                childListitem.task_name == '幽默探索号' ||
                childListitem.task_name == '幽默的降落伞' ||
                childListitem.task_name == '幽默的讲述者' ||
                childListitem.task_name == '生活的积极按钮' ||
                childListitem.task_name == '收获总结' ||
                childListitem.task_name == '倾听信号接收员'
              "
              src="https://lesson.iapeap.com/images/EmotionalValue/hqxxlx.png"
              alt=""
            />
            <img
              v-if="childListitem.task_name == '情景训练场'"
              src="https://lesson.iapeap.com/images/EmotionalValue/hqjxlc.png"
              alt=""
            />
            <img
              v-if="childListitem.task_name == '情绪日记'"
              src="https://lesson.iapeap.com/images/EmotionalValue/hqxrj.png"
              alt=""
            />
            <div>{{ childListitem.task_name }}</div>
          </div>
          <!-- 是否完成 -->
          <img
            class="duigou"
            v-if="childListitem.is_complated == 1"
            src="https://lesson.iapeap.com/images/EmotionalValue/duigou.png"
            alt=""
          />
          <img
            v-if="childListitem.is_favourite == null"
            class="aixin"
            src="https://lesson.iapeap.com/images/EmotionalValue/xinhuiaixin.png"
            alt=""
          />
          <img
            v-if="childListitem.is_favourite == 1"
            class="aixin"
            src="https://lesson.iapeap.com/images/EmotionalValue/aixin.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="ctxItem">
        <div class="ctx-title">
          <div class="title-ctx">紧握身边的一个物品</div>
          <div class="title-fk"></div>
        </div>

        <div class="titleitem2">
          <div class="titleitem-left">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/zhinanzhen.png"
              alt=""
            />
            <div>对自己承诺</div>
          </div>
          <img
            class="aixin"
            src="https://lesson.iapeap.com/images/EmotionalValue/aixin.png"
            alt=""
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getPracticeList,
  homePage,
  setFavourite,
  cancelFavourite,
  memberLog,
  getStageIsComplated,
} from "../../api/login";
export default {
  data() {
    return {
      stage: "",
      days: "",
      PracticeList: [],
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
      showover: false,
      accomplish: "0",
      mdeal: "",
    };
  },
  created() {},
  mounted() {
    console.log("列表当前阶段", this.$route.query.stage);
    this.stage = this.$route.query.stage;
    this.getgetPracticeList();
    this.getgetStageIsComplated2();
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  methods: {
    // 获取当前阶段是否全部完成
    getgetStageIsComplated2() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: this.stage,
      };
      getStageIsComplated(data).then((res) => {
        this.accomplish = res.data.status;
        this.mdeal = res.data.mdeal;
        if (this.accomplish !== 0 && !this.mdeal) {
          document.body.style.position = "fixed";
          document.body.style.overflow = "hidden";
          this.showover = !this.showover;
        }
      });
    },

    // 跳转已完成填写勋章报告
    tofillin2() {
      // 允许页面滚动
      document.body.style.position = "relative";
      document.body.style.overflow = "visible";
      this.$router.push({
        path: "/report/fillin",
        query: { stage: this.stage, ok: 1 },
      });
    },
    gotoitem(childListitem) {
      let item = childListitem;
      console.log(item.task_name);
      console.log(item.id);
      if (
        childListitem.task_name == "入营指南" ||
        childListitem.task_name == "唤醒情绪觉察力" ||
        childListitem.task_name == "如何更好地觉察情绪" ||
        childListitem.task_name == "情绪合理化" ||
        childListitem.task_name == "情绪能量转化" ||
        childListitem.task_name == "对情绪的积极回应" ||
        childListitem.task_name == "应对情绪的方法" ||
        childListitem.task_name == "总结与拓展" ||
        childListitem.task_name == "积极倾听的开始" ||
        childListitem.task_name == "打开倾听的空间" ||
        childListitem.task_name == "捕捉倾听中的信息" ||
        childListitem.task_name == "深层次的倾听——共情" ||
        childListitem.task_name == "倾听中的支持性回应" ||
        childListitem.task_name == "调整倾听中的阻碍" ||
        childListitem.task_name == "沟通表达的力量" ||
        childListitem.task_name == "从客观事实出发" ||
        childListitem.task_name == "对自己的感受负责" ||
        childListitem.task_name == "良好沟通表达的助力——站在对方的视角" ||
        childListitem.task_name == "多提请求，而不是要求" ||
        childListitem.task_name == "应对沟通中的矛盾" ||
        childListitem.task_name == "幽默的积极力量" ||
        childListitem.task_name == "用适当的方式幽默" ||
        childListitem.task_name == "探索属于你的幽默感" ||
        childListitem.task_name == "幽默的化解力" ||
        childListitem.task_name == "幽默的修复力" ||
        childListitem.task_name == "丰富生活中的积极体验"
      ) {
        this.$router.push({
          path: "/setout/handbook",
          query: { item: item },
        });
      }

      if (
        childListitem.task_name == "情绪探照灯" ||
        childListitem.task_name == "情绪命名挑战" ||
        childListitem.task_name == "情绪逻辑师" ||
        childListitem.task_name == "情绪探险家" ||
        childListitem.task_name == "情绪的时光机" ||
        childListitem.task_name == "情绪呼吸阀" ||
        childListitem.task_name == "情绪指南秘籍" ||
        childListitem.task_name == "倾听能量显微镜" ||
        childListitem.task_name == "倾听空间建筑师" ||
        childListitem.task_name == "倾听信号转接员" ||
        childListitem.task_name == "共情对讲机" ||
        childListitem.task_name == "倾听回应能量阀" ||
        childListitem.task_name == "倾听保护伞" ||
        childListitem.task_name == "倾听指南秘籍" ||
        childListitem.task_name == "沟通计划书" ||
        childListitem.task_name == "客观的表达者" ||
        childListitem.task_name == "感受代言人" ||
        childListitem.task_name == "同频输出者" ||
        childListitem.task_name == "请求提出者" ||
        childListitem.task_name == "冲突化解力" ||
        childListitem.task_name == "表达指南秘籍" ||
        childListitem.task_name == "幽默的线索" ||
        childListitem.task_name == "幽默的指南针" ||
        childListitem.task_name == "幽默探索号" ||
        childListitem.task_name == "幽默的降落伞" ||
        childListitem.task_name == "幽默的讲述者" ||
        childListitem.task_name == "生活的积极按钮" ||
        childListitem.task_name == "收获总结" ||
        childListitem.task_name == "倾听信号接收员"
      ) {
        if (item.is_complated == 1) {
          this.$router.push({
            path: "/setout/ckninjia",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/setout/ninjia",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "情景训练场") {
        this.$router.push({
          path: "/setout/trainingground",
          query: { item: item },
        });
        window.localStorage.setItem("listid", item.id);
      }
      if (item.task_name == "情绪日记") {
        if (item.is_complated !== 1) {
          this.$router.push({
            path: "/emotion/diary",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/emotion/ckdiary",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "对自己的承诺") {
        if (item.is_complated == 1) {
          this.$router.push({
            path: "/setout/check",
            query: { item: item },
          });
        } else {
          console.log(item);
          this.$router.push({
            path: "/setout/mepromise",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "情绪能量测验") {
        console.log(item);
        if (item.task_day === 0) {
          this.$router.push({
            path: "/test/worth",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/welcomeH",
            query: { item: item },
          });
        }
      }
    },
    // 点击收藏
    getsetFavourite(childListitem) {
      console.log(childListitem.id);

      let data = {
        userId: window.localStorage.getItem("userId"),
        id: childListitem.id,
      };
      setFavourite(data).then((res) => {
        this.getgetPracticeList();
      });
    },
    // 取消收藏
    getcancelFavourite(childListitem) {
      console.log(childListitem.id);
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: childListitem.id,
      };
      cancelFavourite(data).then((res) => {
        this.getgetPracticeList();
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    gohome() {
      this.$router.push("/");
    },

    getgetPracticeList() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在加载...",
        forbidClick: true,
      });
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: this.stage,
      };
      getPracticeList(data).then((res) => {
        Toast.clear();
        this.PracticeList = res.data;
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "练习列表",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.topracticelist {
  background-color: #f4fafe;
  padding: 20px;
  box-sizing: border-box;
  .duigou {
    width: 30px;
  }

  .ctxItem {
    margin-bottom: 20px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  .titleitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 48px;
    background: #eaf2fe;
    box-shadow: 0px 2px 8px 0px rgba(43, 106, 186, 0.3);
    border-radius: 8px;
    .titleitem-left {
      display: flex;
      align-items: center;
      gap: 10px;
      // width: 150px;
      .task_name {
        font-size: 13px;
      }
      img {
        width: 20px;
      }
    }
    .aixin {
      width: 20px;
      height: 20px;
    }
  }
}

.titleitem2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 20px;
  height: 48px;
  background: #e8e8e8;
  box-shadow: 0px 2px 8px 0px #d6d5d5;
  border-radius: 8px;
  color: #999999;
  .titleitem-left {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 20px;
    }
  }
  .aixin {
    width: 20px;
    height: 20px;
  }
}

.ctx-title {
  .title-ctx {
    z-index: 998;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #000025;
    line-height: 25px;
  }
  .title-fk {
    width: 35px;
    height: 5px;
    background: #629ee9;
    position: relative;
    bottom: 8px;
    z-index: 998;
    transform: skew(-25deg);
  }
}
.over {
  position: relative;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(250, 253, 254, 0.9);
  .overbox {
    width: 300px;
    height: 304px;
    background-color: #fff;
    box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
    text-align: center;
    color: #333;
    img {
      position: relative;
      // left: calc(50% - 75px);
      bottom: 30px;
      width: 150px;
      height: 140px;
    }
    button {
      color: #fff;
      border-radius: 25px;
      background-color: #72acf4;
      width: 224px;
      height: 36px;
      border: 0;
      margin-top: 30px;
    }
  }
}
</style>
